import React from "react";
import { Link, useMatch, useResolvedPath } from 'react-router-dom';
import logo from '../assets/logo.png';
import { auth } from "../firebase";
import { signOut } from "firebase/auth";

function CustomLink({ toPath, children, ...props }) {
	const resolvedPath = useResolvedPath(toPath);
	const isActive = useMatch({ path: resolvedPath.pathname });
	const classNames = isActive ? "nav-item active" : "nav-item";
	return (
		<li className={classNames}>
			<Link className="nav-link" {...props} to="profile">
				{children}
			</Link>
		</li>);
}

export default function Navbar() {
	async function userSignOut() {
		await signOut(auth);
	}

	return (
		<>
			<nav className="navbar navbar-expand-lg bg-body-tertiary mb-5">
				<div className="container">
					<Link className="navbar-brand" to={'/'}>
						<img src={logo} alt="Sweate" height="36"/>
					</Link>
					<button
						className="navbar-toggler"
						type="button"
						data-bs-toggle="collapse"
						data-bs-target="#navbarSupportedContent"
						aria-controls="navbarSupportedContent"
						aria-expanded="false"
						aria-label="Toggle navigation"
					>
						<span className="navbar-toggler-icon"></span>
					</button>
					<div className="collapse navbar-collapse" id="navbarSupportedContent">
						<ul className="navbar-nav ms-auto mb-2 mb-lg-0">
							<CustomLink toPath={'/profile'}
											children={auth.currentUser.displayName ?? auth.currentUser.uid}/>
							<li className="nav-item" onClick={userSignOut}>
								<span className="nav-link" style={{ cursor: 'pointer' }}>
								Logout
								</span>
							</li>
						</ul>
					</div>
				</div>
			</nav>
		</>
	);
}
