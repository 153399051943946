import { initializeApp } from 'firebase/app';
import { getAuth } from "firebase/auth";
import { getStorage, ref } from "firebase/storage";
import { getDatabase } from "firebase/database";
import { getFirestore } from 'firebase/firestore';

const isLive = true;


const firebaseConfig = {
	apiKey: "AIzaSyBsUnZJHz7kS70Jk4T1i7j8NMx1AHLwqZ8",
	authDomain: "sweate-338bd.firebaseapp.com",
	databaseURL: isLive ? "https://sweate-338bd-default-rtdb.firebaseio.com" : "https://sweate-dev.firebaseio.com/",
	projectId: "sweate-338bd",
	storageBucket: "sweate-338bd.appspot.com",
	messagingSenderId: "702742448398",
	appId: "1:702742448398:web:81ac44936226ef089aaf5f",
	measurementId: "G-V6MB9SYJRB"
};

const fbProdApp = initializeApp(firebaseConfig, 'sweate_prod');

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(fbProdApp);
export const db = getDatabase(fbProdApp);

export const fs = getFirestore(fbProdApp);

export const fbStorage = getStorage(fbProdApp)
export const fbStorageRef = ref;

export const USER_CONTENT = isLive ? 'user_content' : 'user_content_test';
export const POST_CONTENT = isLive ? 'post_media' : 'post_media_test';
export const DISPLAY_PHOTO_CONTENT = isLive ? 'display_photos' : 'display_photos_test';

export const SWEATE_API_URL = isLive ? 'https://dolphin-app-p9rte.ondigitalocean.app/' : 'http://127.0.0.1:5000/remove_user';
