import React, { useEffect, useState } from "react";
import {
	auth,
	db,
	DISPLAY_PHOTO_CONTENT,
	fbStorage,
	fbStorageRef,
	POST_CONTENT, SWEATE_API_URL,
	USER_CONTENT
} from "../firebase";
import { listAll, deleteObject } from "firebase/storage";

import {
	ref,
	onValue,
	get,
	orderByKey,
	orderByChild,
	query,
	remove,
	equalTo
} from "firebase/database";
import login from "./Login";
import axios from "axios";

export default function Dashboard() {
	const USER_GOAL = 100;
	const [ userData, setUserData ] = useState([]);
	const [ inProgressWorkoutData, setInProgressWorkoutData ] = useState([]);
	const [ workoutData, setWorkoutData ] = useState([]);

	useEffect(() => {
		document.title = 'Dashboard | Sweate';
		getUserData();
		getInProgressWorkoutData();
		getWorkoutData();

	}, []);

	function getWorkoutData() {
		const workoutRef = ref(db, 'WorkoutData/workouts');
		onValue(workoutRef, (snapshot) => {
			const data = snapshot.val();
			if (data !== null) {
				const workouts = []
				Object.values(data).map((userWorkoutGroup) => {
					Object.values(userWorkoutGroup).map((workout) => {
						workouts.push(workout);
					});
				});
				setWorkoutData(workouts);
			} else {
				setWorkoutData([]);
			}
		}, (error) => {
			console.log('Error reading workout data:', error.message);
		});
	}

	function getInProgressWorkoutData() {
		const checkinRef = ref(db, 'WorkoutData/workouts/in_progress');
		onValue(checkinRef, (snapshot) => {
			const data = snapshot.val();

			if (data !== null) {
				const checkins = []
				Object.values(data).map((checkin) => {
					checkins.push(checkin);
				});
				setInProgressWorkoutData(checkins);
			} else {
				setInProgressWorkoutData([]);
			}
		}, (error) => {
			console.log('Error reading in progress workout data:', error.message);
		});
	}

	function getUserData() {
		const userRef = ref(db, 'UserData');
		onValue(userRef, (snapshot) => {
			const data = snapshot.val();

			if (data !== null) {
				const users = []
				Object.values(data).map((user) => {
					users.push(user);
				});
				setUserData(users);
			} else {
				setUserData([]);
			}
		}, (error) => {
			console.log('Error reading users:', error.message);
		});
	}

	function removeCommunityData(uid) {
		const commRef = query(ref(db, 'CommunityData'), orderByKey());
		get(commRef).then((snapshot) => {
			if (snapshot.exists) {
				snapshot.forEach((comm) => {
					const commSize = comm.size;
					if (commSize > 0) {
						comm.forEach((member) => {
							if (member.key === uid) {
								// remove member ref
								remove(member.ref).then(() => console.log('Community data removed for user ', uid));
							}
						});
					}

				});
			} else {
				console.log('Found no data');
			}
		}).catch((error) => {
			console.error(error);
		})
	}

	function removeDeviceTokenData(uid) {
		const devTokenRef = query(ref(db, 'DeviceTokenData'), orderByKey(), equalTo(uid));
		get(devTokenRef).then((snapshot) => {
			if (snapshot.exists()) {
				if (snapshot.size > 0) {
					snapshot.forEach((token) => {
						if (token.key === uid) {
							remove(token.ref).then(() => console.log('Device Token data removed for user ', uid));
						}
					})
				}
			}
		}).catch((error) => console.error(error));
	}

	function removeNotificationData(uid) {
		const inNotificationRef = query(ref(db, 'NotificationData'), orderByChild('recipient_id'), equalTo(uid));
		const outNotificationRef = query(ref(db, 'NotificationData'), orderByChild('sender_id'), equalTo(uid));

		// Remove incoming notifications for user
		get(inNotificationRef).then((snapshot) => {
			if (snapshot.exists()) {
				snapshot.forEach((notification) => {
					remove(notification.ref).then(() => {
					});
				});

				console.log('incoming notifications removal complete.')
			}
		}).catch((error) => console.error(error));

		// Remove outgoing notifications for user
		get(outNotificationRef).then((snapshot) => {
			if (snapshot.exists()) {
				snapshot.forEach((notification) => {
					remove(notification.ref).then(() => {
					});
				});

				console.log('outgoing notifications removal complete.')
			}
		}).catch((error) => console.error(error));
	}

	function removeWorkoutData(uid) {
		const completeWorkoutRef = query(ref(db, 'WorkoutData/workouts/complete'), orderByChild('user_id'), equalTo(uid));
		const currentWorkoutRef = query(ref(db, 'WorkoutData/workouts/in_progress'), orderByChild('user_id'), equalTo(uid));
		const workoutActivityRef = ref(db, 'WorkoutData/workout_activities/' + uid);
		const workoutSetRef = ref(db, 'WorkoutData/workout_sets/' + uid);

		// removing workout sets
		get(workoutSetRef).then((snapshot) => {
			if (snapshot.exists()) {
				snapshot.forEach((workout) => {
					remove(workout.ref).then();
				});
			}
		}).catch((error) => console.error(error));

		// removing workout activities
		get(workoutActivityRef).then((snapshot) => {
			if (snapshot.exists()) {
				snapshot.forEach((workout) => {
					remove(workout.ref).then();
				});
			}
		}).catch((error) => console.error(error));

		// removing incomplete workouts
		get(currentWorkoutRef).then((snapshot) => {
			if (snapshot.exists()) {
				snapshot.forEach((workout) => {
					remove(workout.ref).then();
				});
			}
		}).catch((error) => console.error(error));

		// removing completed workouts
		get(completeWorkoutRef).then((snapshot) => {
			if (snapshot.exists()) {
				snapshot.forEach((workout) => {
					remove(workout.ref).then();
				});
			}
		}).catch((error) => console.error(error));


	}

	function removeContentData(uid) {
		const commentLikesRef = query(ref(db, 'ContentData/comment_likes'), orderByKey());
		const commentsRef = query(ref(db, 'ContentData/comments'), orderByKey());
		const postLikeRef = query(ref(db, 'ContentData/post_likes'));
		const postShares = query(ref(db, 'ContentData/post_shares'));
		const postViews = query(ref(db, 'ContentData/post_views'));
		const postRef = query(ref(db, 'ContentData/posts'), orderByChild('author'), equalTo(uid));

		// remove post data
		get(postRef).then((snapshot) => {
			if (snapshot.exists()) {
				snapshot.forEach((postRef) => {
					remove(postRef.ref).then();
				});

				// remove storage post data
				const postBucketRef = fbStorageRef(fbStorage, USER_CONTENT + '/' + POST_CONTENT + '/' + uid);
				listAll(postBucketRef).then((res) => {
					res.items.forEach((itemRef) => {
						deleteObject(itemRef).then(() => console.log('deleted post: ', itemRef.name)).catch((error) => console.error(error));
					});
				}).catch((error) => console.error(error));

				// remove storage profile photo data
				const dpBucketRef = fbStorageRef(fbStorage, USER_CONTENT + '/' + DISPLAY_PHOTO_CONTENT + '/' + uid);
				listAll(dpBucketRef).then((res) => {
					res.items.forEach((itemRef) => {
						deleteObject(itemRef).then(() => console.log('deleted dp:', itemRef.name)).catch((error) => console.error(error));
					});
				}).catch((error) => console.error(error));
			}
		}).catch((error) => console.error(error));

		// remove post view data
		get(postViews).then((snapshot) => {
			if (snapshot.exists()) {
				snapshot.forEach((postViewSnapshot) => {
					const views = postViewSnapshot.size;
					if (views > 0) {
						postViewSnapshot.forEach((view) => {
							if (view.key === uid) {
								remove(view.ref).then();
							}
						})
					}
				})
			}
		}).catch((error) => console.error(error));

		// remove post share data
		get(postShares).then((snapshot) => {
			if (snapshot.exists()) {
				snapshot.forEach((postShareSnapshot) => {
					const shares = postShareSnapshot.size;
					if (shares > 0) {
						postShareSnapshot.forEach((share) => {
							if (share.key === uid) {
								remove(share.ref).then();
							}

						})
					}
				})
			}
		}).catch((error) => console.error(error));

		// remove post like data
		get(postLikeRef).then((snapshot) => {
			if (snapshot.exists()) {
				snapshot.forEach((postLikeSnapshot) => {
					const postLikes = postLikeSnapshot.size;
					if (postLikes > 0) {
						postLikeSnapshot.forEach((like) => {
							if (like.key === uid) {
								remove(like.ref).then();
							}
						});
					}
				});
			}
		}).catch((error) => console.error(error));


		// remove comments data
		get(commentsRef).then((snapshot) => {
			if (snapshot.exists()) {
				snapshot.forEach((postRef) => {
					if (postRef.exists() && postRef.size > 0) {
						postRef.forEach((commentRef) => {
							if (commentRef.val()['author_id'] === uid) {
								remove(commentRef.ref).then();
							}
						});
					}
				});
			}

			console.log('Comment data removal complete.');
		}).catch((error) => console.error(error));


		// remove comment like data
		get(commentLikesRef).then((snapshot) => {
			if (snapshot.exists()) {
				snapshot.forEach((commentLikesSnapshot) => {
					const likesPerComment = commentLikesSnapshot.size;
					if (likesPerComment > 0) {
						commentLikesSnapshot.forEach((like) => {
							if (like.key === uid) {
								remove(like.ref).then();
							}
						});
					}
				});
			}
		}).catch((error) => console.error(error));
	}

	function removeUserProfileData(uid) {
		const profileRef = ref(db, 'UserData/' + uid);

		// remove entire profile data
		get(profileRef).then((snapshot) => {
			if (snapshot.exists()) {
				// remove friend data
				const friends = snapshot.val()['friends'] ?? [];
				Object.keys(friends).forEach((key) => {
					// key is friends uid - use this to remove current uid record from their friends list
					const friendRef = ref(db, 'UserData/' + key + '/friends/' + uid);
					remove(friendRef).then(() => {
						console.log(key + ' no longer friends connected with ' + uid);
					}).catch((error) => console.error(error));
				});

				// remove incoming / outgoing request data
				const incomingRequests = snapshot.val()['incoming_requests'] ?? [];
				Object.keys(incomingRequests).forEach((key) => {
					// key is friends uid - use this to remove current uid record from their friends list
					const friendRef = ref(db, 'UserData/' + key + '/outgoing_requests/' + uid);
					remove(friendRef).then(() => {
						console.log(key + ' no longer requesting connection with ' + uid);
					}).catch((error) => console.error(error));
				});

				const outgoingRequests = snapshot.val()['outgoing_requests'] ?? [];
				Object.keys(outgoingRequests).forEach((key) => {
					// key is friends uid - use this to remove current uid record from their friends list
					const friendRef = ref(db, 'UserData/' + key + '/incoming_requests/' + uid);
					remove(friendRef).then(() => {
						console.log(key + ' connection request removed with ' + uid);
					}).catch((error) => console.error(error));
				});

				// remove incoming / outgoing waves data
				const incomingWaves = snapshot.val()['incoming_waves'] ?? [];
				Object.keys(incomingWaves).forEach((key) => {
					// key is friends uid
					const waveRef = ref(db, 'UserData/' + key + '/outgoing_waves/' + uid);
					remove(waveRef).then(() => {
						console.log(key + ' removed outgoing wave to ' + uid);
					}).catch((error) => console.error(error));
				});

				const outgoingWaves = snapshot.val()['outgoing_waves'] ?? [];
				console.log('outgoing waves: ', outgoingWaves);
				Object.keys(outgoingWaves).forEach((key) => {
					// key is friends uid
					const waveRef = ref(db, 'UserData/' + key + '/incoming_waves/' + uid);
					remove(waveRef).then(() => {
						console.log(key + ' removed incoming wave from ' + uid);
					}).catch((error) => console.error(error));
				});

				// finally Remove profile
				remove(snapshot.ref).then(() => console.log('User profile removed: ' + uid));

				// remove firebase auth account
				// TODO: Host Sweate API in cloud and update api link
				axios.post(SWEATE_API_URL, {
					'uid': uid,
				}).then((response) => console.log(response.status, response.data))
					.catch((error) => {
						if (error.response) {
							console.error(error.response);
							console.error('server responded.');
						} else if (error.request) {
							console.error(error.response);
							console.error('network error.');
						} else {
							console.error(error);
						}
					})

			}
		}).catch((error) => console.error(error));
	}

	function removeUser(uid) {
		if (window.confirm('Remove user ' + uid + '?')) {
			console.log('removing user ' + uid);

			// remove community data
			removeCommunityData(uid);

			// remove Device Token data
			removeDeviceTokenData(uid);

			// remove notification data
			removeNotificationData(uid);

			// remove Workout data
			removeWorkoutData(uid);

			//  remove content data (from rtdb and storage)
			removeContentData(uid);

			// remove user data (from rtdb and auth)
			removeUserProfileData(uid);

			console.log('User removal complete.');
		} else {
			console.log('Operation cancelled.');
		}
	}

	const userDataRows = userData.map((user) => {
		const profile = user['profile']
		return (
			<tr key={profile['id']}>
				<th scope="row" className='text-small' style={{ 'cursor': 'pointer' }}
					 onClick={() => removeUser(profile['id'])}>❌
				</th>
				<th scope="row">{profile.id}</th>
				<td>{profile['first_name'] != null ? profile['first_name'].toLowerCase() : ''}</td>
				<td>{profile['last_name'] != null ? profile['last_name'].toLowerCase() : ''}</td>
				<td>{profile['email']}</td>
				<td>{profile['premium'] ? 'YES' : 'NO'}</td>
				<td>{profile['created_at'] != null ? new Date(profile['created_at']).toLocaleString('en-US', {
					month: 'long',
					day: 'numeric',
					year: 'numeric'
				}) : 'N/A'}</td>
				{/*<td>Edit</td>*/}
			</tr>
		);
	});

	return (
		<>
			<div className="row mt-5">
				<div className="col">
					<h3 className="mb-3">At a Glance</h3>
					<hr/>
				</div>
			</div>
			<div className="row py-3">
				<div className="col-lg-4">
					<div className="card">
						<div className="card-body">
							<h1>{userData.length} <span
								className={'h6'}>User{userData.length === 1 ? '' : 's'}</span></h1>
							<div className="progress mt-5" role="progressbar" aria-label="User Goal"
								  aria-valuenow={(userData.length / USER_GOAL) * 100}
								  aria-valuemin="0" aria-valuemax="100" style={{ height: '5px' }}>
								<div className="progress-bar"
									  style={{ width: `${(userData.length / USER_GOAL) * 100}%` }}></div>
							</div>
							<div className="row mt-1">
								<div className="col">
									<p className="small text-secondary">Next goal</p>
								</div>

								<div className="col">
									<p className="small text-secondary text-end">{USER_GOAL}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-lg-4">
					<div className="card">
						<div className="card-body">
							<h1>{inProgressWorkoutData.length} <span
								className={'h6'}>Workout{inProgressWorkoutData.length === 1 ? '' : 's'} in Progress</span>
							</h1>
						</div>
					</div>
				</div>
				<div className="col-lg-4">
					<div className="card">
						<div className="card-body">
							<h1>{workoutData.length} <span className={'h6'}>Completed
								Workout{workoutData.length === 1 ? '' : 's'}</span></h1>
						</div>
					</div>
				</div>
			</div>

			<div className="row py-5">
				<div className="col">
					<h3 className="mb-3">Users</h3>
					<hr/>
					<table className="table table-striped">
						<thead>
						<tr>
							<th scope="col"></th>
							<th scope="col">ID</th>
							<th scope="col">First</th>
							<th scope="col">Last</th>
							<th scope="col">Email</th>
							<th scope="col">Premium</th>
							<th scope="col">Member Since</th>
							{/*<th scope="col">Actions</th>*/}
						</tr>
						</thead>
						<tbody>
						{userDataRows}
						</tbody>
					</table>
				</div>
			</div>
		</>
	);
}
