import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import Login from './pages/Login';
import Navbar from './components/Navbar';
import { Navigate, Route, Routes } from 'react-router-dom';
import { auth, fs } from "./firebase";
import { onAuthStateChanged } from "firebase/auth";
import Dashboard from "./pages/Dashboard";
import React, { useEffect, useState } from "react";
import Protected from "./components/Protected";
import { doc, getDoc, serverTimestamp, updateDoc } from "firebase/firestore";


function App() {
	const [ authenticated, setAuthenticated ] = useState(false);

	useEffect(() => {
		const listen = onAuthStateChanged(auth, (user) => {
			if (user !== null) {
				// Check if admin uid is in Firestore admin table
				const docRef = doc(fs, 'admins/' + user.uid);
				getDoc(docRef)
					.then((snapshot) => {
						if (snapshot.exists()) {
							// Update last login date
							updateDoc(docRef, {
								'last_login': serverTimestamp(),
							}).then(() => {
								setAuthenticated(true);
							});
						} else {
							console.log('admin verification failed.');
							auth.signOut().then(() => {
								setAuthenticated(false);
							});
						}
					})
					.catch((error) => {
						console.log('admin snapshot error:', error);
						setAuthenticated(false);
					});
			} else {
				setAuthenticated(false);
			}
		});
		return () => listen();
	}, []);


	return (
		<div className="container">
			{authenticated &&
				<header className="App-header">
					<Navbar/>
				</header>
			}
			<main>
				<Routes>
					<Route path="/" element={authenticated ? <Navigate to={'/dashboard'} replace/> :
						<Navigate to={'/login'} replace/>}/>
					<Route path="/login"
							 element={authenticated ? <Navigate to={'/dashboard'}/> : <Login/>}/>
					<Route path='dashboard'
							 element={<Protected
								 isAuthenticated={authenticated}><Dashboard/></Protected>}
					/>

				</Routes>
			</main>
			<footer className="text-center mt-5">
				<p className="small">Sweate Inc. &copy; {new Date().getFullYear()}</p>
			</footer>
		</div>
	);
}

export default App;
