import logo from '../assets/logo.png';
import { auth } from "../firebase";
import {
	signInWithEmailAndPassword,
	setPersistence,
	browserSessionPersistence
} from "firebase/auth";
import { useEffect, useState } from "react";

function Login() {
	useEffect(() => {
		document.title = 'Login | Sweate';
	}, []);

	const [ email, setEmail ] = useState('');
	const [ password, setPassword ] = useState('');


	const onFormSubmit = (e) => {
		console.log('logging in');
		e.preventDefault();

		if (email.length === 0) {
			alert('Please provide your email');
			return;
		}

		if (password.length === 0) {
			alert('Please enter your password');
			return;
		}

		setPersistence(auth, browserSessionPersistence)
			.then(() => {
				return signInWithEmailAndPassword(auth, email, password)
					.then((userCredential) => {
						// Signed In - navigate to dashboard automatically
					})
					.catch((error) => {
						const errorCode = error.code;
						const errorMessage = error.message;
						console.log(errorCode, ':', errorMessage);
					})
			}).catch((error) => {
			// Handle Errors here.
			const errorCode = error.code;
			const errorMessage = error.message;
			console.log(errorCode, ': ', errorMessage);
		});
	}

	return (
		<>
			<div className="row justify-content-center mt-5">
				<div className="col-3">
					<img src={logo} width={300} alt="Sweate Logo" className="mb-5"/>
					<form className="text-center"
							onSubmit={onFormSubmit}
					>
						<div className="mb-3">
							<input
								type="email"
								value={email}
								placeholder="Email Address"
								className="form-control"
								id="email"
								onChange={(e) => setEmail(e.target.value)}
							/>
						</div>
						<div className="mb-3">
							<input
								type="password"
								value={password}
								placeholder="Password"
								className="form-control"
								id="password"
								onChange={(e) => setPassword(e.target.value)}
							/>
						</div>
						<div className="d-grid gap-2">
							<button type="submit" className="btn btn-primary">
								Sign In
							</button>
						</div>

						{auth.currentUser != null &&
							<p className='mt-3'>{auth.currentUser.uid} is logged in.</p>}
					</form>
				</div>
			</div>
		</>
	);
}

export default Login;
